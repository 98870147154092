import type { SearchData } from '@/types/SearchData'

export const useSearchData = async (inputId: string, query: string): Promise<SearchData | null> => {
  try {
    const requestBody = {
      ajax_call: 'y',
      INPUT_ID: inputId,
      q: query,
      l: 2,
      type: 'search-box'
    };


    const response = await fetch('/include/search.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error('Сетевая ошибка');
    }

    const data: SearchData = await response.json();

    return data;
  } catch (error) {

    return null;
  }
};