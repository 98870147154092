<script setup lang="ts">
import type { TagItem } from '@/types/SearchData'
import { inject, Ref } from 'vue'

const tags = inject<Ref<TagItem[]>>('tags')
const handleTagClick = (tagLabel: string) => {
	const inputElement = document.getElementById(
		'title-search-input'
	) as HTMLInputElement

	if (inputElement) {
		inputElement.value = tagLabel
		const event = new Event('input', {
			bubbles: true,
			cancelable: true,
		})

		inputElement.dispatchEvent(event)
	}
}
</script>
<template>
	<div v-if="tags" class="tags">
		<ul class="tags__list">
			<li
				@click="handleTagClick(tag.label)"
				class="tags__item"
				v-for="tag in tags"
				:key="tag.id"
			>
				{{ tag.label }}
			</li>
		</ul>
	</div>
</template>

<style scoped lang="scss">
.tags {
	display: flex;
	flex-wrap: wrap;
	&__list {
		display: flex;
        flex-wrap: wrap;
		align-items: center;
	}
	&__item {
		padding: 3px 10px 5px;
		margin-right: 6px;
		margin-bottom: 6px;
		list-style-type: none;
		cursor: pointer;
		border-radius: 30px;
		border: 1px solid #ff7cb0;
		color: #000000;
		font-size: 14px;
		transition: 0.2s;
		font-weight: normal;
		&:hover {
			background: #ff7cb0;
		}
	}
}
</style>
