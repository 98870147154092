<script setup lang="ts">
import { defineProps, computed, inject } from "vue";
import type { CategoryItem } from "@/types/SearchData";

const props = defineProps<{
  card: CategoryItem;
}>();

const query = inject<string>('query')

const urlWithSectionId = computed(() => {
  if (query && props.card.count > 0 && props.card.sectionId) {
    const url = new URL(props.card.search.url || '');
    url.searchParams.set('q', query.value);
    url.searchParams.set('sectionId', props.card.sectionId);
    return url.toString();
  }

  return props.card.search.url;
});
</script>

<template>
  <div v-if="props.card" class="category__card">
    <a
      target="_self"
      v-if="props.card.search.url"
      :href="urlWithSectionId"
      class="category__card-list"
    >
      <div class="category__card-prop">
        <span class="category__card-prop-hierarchy" v-if="props.card.hierarchy">{{
          card.hierarchy
        }}</span>
        <h3 class="category__card-prop-title" v-if="props.card.text">
          {{ card.text }}
        </h3>
        <span class="category__card-prop-count" v-if="props.card.count">{{
          card.count
        }}</span>
      </div>
      <span v-if="props.card.name" class="category__card-prop-name">{{
        card.name
      }}</span>
    </a>
  </div>
</template>

<style scoped lang="scss">
.category__card {
  padding: 5px 10px;
  &-list {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    &:hover {
      .category__card-prop-title {
        color: #ff7cb0;
      }

      .category__card-prop-count {
        background-color: #ff7cb0;
        color: white;
      }
    }
  }
  &-prop {
    display: flex;
    width: 100%;
    max-width: 80%;
    align-items: center;
    &-hierarchy {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #737c8f;
      font-size: 0.8em;
    }
    &-name {
      color: #737c8f;
      font-size: 0.85em;
      flex-shrink: 0;
    }
    &-title {
      overflow: hidden;
      font-weight: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.1em;
      transition: color 0.2s ease;
      padding-left: 5px;
    }
    &-count {
      display: inline-flex;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background: rgba(48, 112, 250, 0.05);
      color: #737c8f;
      border-radius: 2px;
      font-size: 0.8em;
      padding: 2px 6px;
      margin-left: 10px;
      transition: background-color 0.2s ease;
    }
  }
}
</style>
