<script setup lang="ts">
import type { GoodsItem } from '@/types/SearchData'
import {  Ref, inject } from 'vue'
import GoodsCard from '@/components/Goods/GoodsCard.vue'

const goods = inject<Ref<GoodsItem[]>>('goods')

</script>

<template>
	<div class="goods">
		<div v-if="goods" class="goods__grid">
			<TransitionGroup name="list" tag="div" class="goods__list">
				<template v-for="good in goods" key="good.id">
					<GoodsCard :card="good" />
				</template>
			</TransitionGroup>
		</div>
	</div>
</template>

<style scoped lang="scss">
.goods {
	position: relative;
	padding: 6px 0;
	display: flex;
	&__grid {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow-x: hidden;
		overflow-y: auto;
		scrollbar-width: thin;
	}
	&__list {
		width: 100%;
	}
}
</style>
