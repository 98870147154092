<script setup lang="ts">
import type { BrandItem } from '@/types/SearchData'
import { inject, Ref } from 'vue'
import BrandCard from '@/components/Brand/BrandCard.vue'

const brands = inject<Ref<BrandItem[]>>('brands')

</script>

<template>
	<div class="brands">
		<div v-if="brands" class="brands__grid">
			<TransitionGroup name="list" tag="div" class="brands__list">
				<template v-for="brand in brands" key="brands.id">
					<BrandCard :card="brand" />
				</template>
			</TransitionGroup>
		</div>
	</div>
</template>

<style scoped lang="scss">
.brands {
	position: relative;
	padding: 6px 0px;
	max-width: 800px;
	margin: 0 auto;
	&::after {
		content: '';
		border-bottom: 1px solid grey;
		position: absolute;
		right: 0.7em;
		left: 0.7em;
		bottom: 0.1em;
	}
	&__grid {
        width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow-x: hidden;
		overflow-y: auto;
		scrollbar-width: thin;
	}
	&__list {
		width: 100%;
	}
}
</style>
