<script setup lang="ts">
import type { CategoryItem } from '@/types/SearchData'
import { Ref, inject } from 'vue'
import CategoryCard from '@/components/Category/CategoryCard.vue'

const categories = inject<Ref<CategoryItem[]>>('categories')
</script>

<template>
	<div class="categories">
		<div v-if="categories" class="categories__grid">
			<TransitionGroup name="list" tag="div" class="categories__list">
				<template v-for="category in categories" key="categories.id">
					<CategoryCard :card="category" />
				</template>
			</TransitionGroup>
		</div>
	</div>
</template>

<style scoped lang="scss">
.categories {
	position: relative;
	padding: 6px 0;
	display: flex;
	&::after {
		content: '';
		border-bottom: 1px solid grey;
		position: absolute;
		right: 0.7em;
		left: 0.7em;
		bottom: 0.1em;
	}
	&__grid {
        width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow-x: hidden;
		overflow-y: auto;
		scrollbar-width: thin;
	}
	&__list {
		width: 100%;
	}
}
</style>
